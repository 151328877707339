import { documentation, email, links, phoneNumber } from "@/utils/const";
import Link from "next/link";
import React from "react";
import styles from "./footer.module.scss";
import { useRouter } from "next/router";

const Footer = () => {
  const router = useRouter();

  const checkActive = (link) =>
    new RegExp(link).test(router.asPath) ? styles.active : "";

  return (
    <footer className={styles.footer}>
      <div className={styles.footerBackground}>
        <div className={styles.footerMain}>
          <nav className={styles.navigation}>
            <h5>Навигация</h5>
            <ul>
              {links.map((item) => (
                <li key={item.title}>
                  <Link className={checkActive(item.href)} href={item.href}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className={styles.documentation}>
            <h5>Документация</h5>
            <ul>
              {documentation.map((item) => (
                <li key={item.title}>
                  <Link
                    className={
                      item.href === "/privacy-policy"
                        ? checkActive(item.href)
                        : ""
                    }
                    href={item.href}
                    target={
                      item.href === "/privacy-policy" ? "_self" : "_blank"
                    }
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.contacts}>
            <h5>Контакты</h5>
            <div>
              <p>Адресс: Боровляны 40 лет Победы - 34</p>
              <p>
                Почта для заявок: <Link href={`mailto:${email}`}>{email}</Link>
              </p>
            </div>
            <h4>{phoneNumber}</h4>
          </div>
        </div>
        <div className={styles.privacy}>
          <Link
            className={checkActive("/privacy-policy")}
            href="/privacy-policy"
          >
            Политика конфидициальности
          </Link>
          <p>© 2023 Web Space. Все права заащищены</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
