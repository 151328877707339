import isMobilePhone from "validator/lib/isMobilePhone";

export const lockScroll = (check) => {
  document.body.style.overflow = check ? "hidden" : "";
  if (document.body.clientWidth > 991) {
    document.body.style.paddingRight = check ? "15px" : "0";
    document.body.querySelector("header nav").style.paddingRight = check
      ? "40px"
      : "25px";
  }
};

export const checkEmpty = (str) => {
  return str.trim() === "";
};

export const leftAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

export const checkPhoneNumber = (number) => {
  return isMobilePhone(
    number
      .replace(/\(/, "")
      .replace(/\)/, "")
      .replace(/\-/, "")
      .replace(/\-/, "")
      .replaceAll(" ", ""),
    ["be-BY", "ru-RU"],
  );
};
