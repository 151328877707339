import Head from "next/head";
import React, { Suspense } from "react";
import "@/styles/main.scss";
import Layout from "@/components/Layout";
import { useRouter } from "next/router";
import { Oswald } from "@next/font/google";
import Context from "@/components/Context";
import { YandexMetrika } from "@/components/seo/YandexMetrika";

const oswald = Oswald({
  weight: ["500", "400", "300", "200"],
  style: ["normal"],
  subsets: ["latin"],
  display: "swap",
});

const App = ({ Component, pageProps }) => {
  const router = useRouter();

  const checkWhiteTheme = !![
    "/portfolio",
    "/price-calculator",
    "/privacy-policy",
  ].find((item) => new RegExp(item).test(router.asPath));

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover"
        />

        <meta
          name="theme-color"
          content={checkWhiteTheme ? "white" : "black"}
        />
        <title>Web-space</title>
      </Head>

      <main className={oswald.className}>
        <Context>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Context>
      </main>
      <Suspense>
        <YandexMetrika />
      </Suspense>
    </>
  );
};

export default App;
