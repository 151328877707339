import React, { useContext, useState } from "react";
import styles from "./navbar.module.scss";
import Link from "next/link";
import Image from "next/image";
import { links, phoneNumber } from "@/utils/const";
import BurgerMenu from "../BurgerMenu";
import { useRouter } from "next/router";
import { lockScroll } from "@/utils/helper";
import { useCustomScroll } from "@/hooks/use-custom-scroll";
import { ModalContext } from "@/components/Context";
import { useResize } from "@/hooks/use-resize";

const Navbar = () => {
  const router = useRouter();
  const { isScreenLg } = useResize();
  const scroll = useCustomScroll();
  const [activeBurgerMenu, setActiveBurgerMenu] = useState(false);

  const { activeModalCallback, setActiveModalCallback } =
    useContext(ModalContext);

  const checkActive = (link) =>
    new RegExp(link).test(router.asPath) ? styles.active : "";

  const checkWhiteTheme = !![
    "/portfolio",
    "/price-calculator",
    "/privacy-policy",
  ].find((item) => new RegExp(item).test(router.asPath));

  const changeActiveBurgerMenu = (active) => () => {
    lockScroll(active);
    setActiveBurgerMenu(active);
  };

  return (
    <>
      <header
        className={`${styles.navbar} ${scroll > 1 ? styles.fixed : ""} ${activeModalCallback ? styles.fixedMobile : ""}`}
      >
        <nav
          className={
            checkWhiteTheme && !activeModalCallback
              ? styles.whiteTheme
              : styles.blackTheme
          }
        >
          <Link href="/">
            <Image alt="web-space" src="/logo.svg" width={55} height={55} />
          </Link>

          <ul>
            {links.map((link) => (
              <li key={link.href}>
                <Link className={checkActive(link.href)} href={link.href}>
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>

          <div className={styles.info}>
            <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>

            <button
              onClick={
                activeModalCallback
                  ? () => setActiveModalCallback(false)
                  : changeActiveBurgerMenu(true)
              }
            >
              <Image
                className={`lg:max-h-[20px] lg:w-[40px] ${activeModalCallback && !isScreenLg ? "hidden" : "block"}`}
                alt="бургер меню"
                src={"/icons/burger.svg"}
                width={40}
                height={23}
              />
              <Image
                className={`lg:max-h-[20px] lg:w-[40px] ${activeModalCallback && !isScreenLg ? "block" : "hidden"}`}
                alt="выход"
                src={"/exit.svg"}
                width={40}
                height={20}
              />
            </button>
          </div>
        </nav>
        {activeModalCallback ? null : (
          <div className={styles.wrapperPhoneNumber}>
            <Link href={`tel:${phoneNumber}`} className={styles.phoneNumber}>
              <Image
                alt="телефон"
                src="/icons/phone.svg"
                width={40}
                height={23}
              />
              <h5>{phoneNumber}</h5>
            </Link>
          </div>
        )}
      </header>
      <BurgerMenu
        active={activeBurgerMenu}
        setActive={changeActiveBurgerMenu(false)}
      />
    </>
  );
};

export default Navbar;
