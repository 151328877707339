import React, { useContext } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import GoogleMap from "../GoogleMap";
import { ModalContext } from "@/components/Context";
import dynamic from "next/dynamic";
import { useResize } from "@/hooks/use-resize";

const CustomButton = dynamic(() => import("../CustomButton"), { ssr: false });
const ModalCallback = dynamic(() => import("../ModalCallback"), { ssr: false });

const Layout = ({ children }) => {
  const { activeModalCallback, setActiveModalCallback } =
    useContext(ModalContext);
  const { isScreenLg } = useResize();

  return (
    <>
      <Navbar />
      <ModalCallback
        fullPage={true}
        description={true}
        setActiveModal={setActiveModalCallback}
        activeModal={activeModalCallback}
      />
      {children}

      <GoogleMap />
      {!isScreenLg && (
        <CustomButton
          onClick={() => setActiveModalCallback(true)}
          anim={true}
          onlyMobile={true}
          theme="main"
        >
          Обратный звонок
        </CustomButton>
      )}

      <Footer />
    </>
  );
};

export default Layout;
