export const phoneNumber = "+375 (44) 752-44-85";
export const email = "orders@web-space.by";
export const address = "ул.40 лет Победы, д. 34 оф. 5/2, кв.242";
export const instagram = "https://www.instagram.com/web_space.by";
export const telegram = "https://t.me/Matsukevi4";
export const viber = `viber://chat?number=+375447524485`;
export const whatsapp = `https://wa.me/+375447524485`;

export const links = [
  { title: "Разработка", href: "/development" },
  { title: "Продвижение", href: "/promotion" },
  { title: "Портфолио", href: "/portfolio" },
  { title: "О нас", href: "/team" },
  { title: "Контакты", href: "/contacts" },
];

export const documentation = [
  { title: "Политика персональных данных", href: "/privacy-policy" },
  { title: "Бриф на разработку", href: "/development-brief.pdf" },
  { title: "Договор на услуги", href: "/service-contract.pdf" },
  { title: "Реквизиты", href: "/requisites.pdf" },
];

export const socialMedia = [
  { href: instagram, src: "/icons/instagram.svg" },
  { href: telegram, src: "/icons/telegram.svg" },
  { href: viber, src: "/icons/viber.svg" },
  { href: whatsapp, src: "/icons/whatsapp.svg" },
];

export const stepsWork = [
  {
    title: "Бриф",
    description:
      "Договариваемся о созвоне и обсуждаем все детали проекта . Согласовываем сроки и стоимость.",
    src: "/home/brif.webp",
  },
  {
    title: "Создание макета",
    additionalTitle: "и набросков",
    description:
      "Договариваемся о созвоне и обсуждаем все детали проекта . Согласовываем сроки и стоимость.",
    src: "/home/UI.webp",
  },
  {
    title: "Заключение договора",
    description:
      "Договариваемся о созвоне и обсуждаем все детали проекта . Согласовываем сроки и стоимость.",
    src: "/home/offer.webp",
  },
  {
    title: "Создаем уникальный дизайн",
    description:
      "Договариваемся о созвоне и обсуждаем все детали проекта . Согласовываем сроки и стоимость.",
    src: "/home/design.webp",
  },
  {
    title: "Программируем",
    description:
      "Договариваемся о созвоне и обсуждаем все детали проекта . Согласовываем сроки и стоимость.",
    src: "/home/programming.webp",
  },
  {
    title: "Тестируем",
    description:
      "Договариваемся о созвоне и обсуждаем все детали проекта . Согласовываем сроки и стоимость.",
    src: "/home/test.webp",
  },
  {
    type: "button",
    title: "Запускаем",
    src: "/home/start.webp",
  },
];

export const advantages = [
  {
    title: "Надежно",
    list: [
      "Никаких конструкторов",
      "Юридическое оформление сделки",
      "Полное сопровождение",
      "Гарантия 12 месяцев",
    ],
    src: "/home/slide1.webp",
  },
  {
    title: "Красиво",
    list: [
      "Уникальный дизайн",
      "Создание анимации",
      "Разработка логотипа при необходимости",
      "Обработка исходников",
    ],
    src: "/home/slide2.webp",
  },
  {
    title: "Удобно",
    list: [
      "Работы под ключ",
      "Возможность продвижения",
      "Подбор доменного имени",
      "Хостинг сайта",
    ],
    src: "/home/slide3.webp",
  },
];

export const sites = [
  {
    title: "Женские украшения  My Vision",
    tags: ["Онлайн-склад", "Разработка", "CMS", "Под ключ"],
    src: "/sites/myvision.webp",
    href: "https://myvision.by/",
  },
  {
    title: "Косметика Balmy",
    tags: ["Интернет-магазин", "Эквайринг", "Складской учет"],
    src: "/sites/balmy.webp",
    href: "https://balmy.by/",
  },
  {
    title: "ОАО «БПА Белстройиндустрия»",
    tags: ["Корпоративный сайт", "SEO-продвижение"],
    src: "/sites/mobile.webp",
    href: "https://bpabsi.by/",
  },
  {
    title: "ООО «ПРАЙМРУФ»",
    tags: ["Сайт-каталог", "Под ключ"],
    src: "/sites/roof.webp",
    href: "https://www.primeroof.by/",
  },
  {
    title: "ООО «Ремкотлопроект»",
    tags: ["Готовый дизайн", "Корпоративный сайт", "Каталог"],
    src: "/sites/bulding.webp",
    href: "https://www.rkpbel.by/",
  },
  {
    title: "Сдача Металлолома",
    tags: ["Лендинг", "Контекстная реклама", "За 2 Дня!"],
    src: "/sites/trash.webp",
    href: "https://metallolom74.by/",
  },

  {
    title: "ООО “САС Энерджи”",
    tags: ["Корпоративный сайт", "Бюджетно"],
    src: "/sites/house.webp",
    href: "https://sas-e.by/",
  },
];
//Home
export const ourServices = [
  {
    title: "Разработка сайтов",
    descrtiption: [
      "Любые виды сайтов",
      "Создание Веб-приложений",
      "Техническая поддержка и консультация",
      "Закрытие проектов Под Ключ",
      "Уникальный дизайн",
      "Никаких конструкторов",
    ],
    src: "/home/service1.webp",
    active: true,
    link: "/development",
  },
  {
    title: "Любой функционал ",
    descrtiption: [
      "Внедрение складского учета",
      "Подключение системы онлайн-платежей",
      "Работа с любыми сторонними API",
      "Подключение CRM на выбор клиента",
      "Создание уникального функционала",
    ],
    src: "/home/service2.webp",
    active: false,
    link: "/portfolio",
  },
  {
    title: "Интернет-маркетинг",
    descrtiption: [
      "Контекстная реклама",
      "SEO - продвижение",
      "Таргетированная реклама",
      "SMM - продвижение",
      "Создание рекламных баннеров",
    ],
    src: "/home/service3.webp",
    active: false,
    link: "/promotion",
  },
];

//For CalculatorPrice

export const typesSytes = [
  {
    title: "Лендинг",
    description:
      "Лендинг – это веб-страница, основной целью которой является побуждение посетителя сделать одно конкретное действие. Этим действием может быть покупка товара, заказ услуги, оформление подписки или регистрация на сайте. Грамотный подход к разработке лендинга всегда предполагает глубокое погружение в бизнес.",
    src: "/development/landing.svg",
    width: 311,
    height: 252,
    price: 600,
  },
  {
    title: "Интернет-магазин",
    description:
      "Интернет-магазин - это разновидность веб-сайта, предназначенного для осуществления торговых операций через Интернет. Этот тип веб-сайта ориентирован на продажу товаров или услуг в онлайн-режиме. Интернет-магазин предоставляет онлайн-каталог с подробным описанием товаров или услуг. Клиенты могут просматривать различные категории, искать продукты и ознакамливаться с характеристиками.",
    src: "/development/magazin.svg",
    width: 267,
    height: 251,
    price: 1800,
  },
  {
    title: "Корпоративный сайт",
    description:
      "Корпоративный сайт - это веб-сайт, предназначенный для представления информации о компании, её продуктах, услугах и контактной информации. Корпоративные сайты служат важным инструментом для создания положительного образа компании в онлайне, предоставляя информацию стейкхолдерам и клиентам, а также обеспечивая место для взаимодействия с аудиторией.",
    src: "/development/corporate.svg",
    width: 323,
    height: 248,
    price: 1500,
  },
  {
    title: "Промо-сайт",
    description:
      "Промо-сайт, или рекламный сайт, представляет собой веб-ресурс, созданный с целью эффективной промоции конкретного продукта, услуги, события или мероприятия. Промо-сайты часто создаются для временных мероприятий или акций, направленных на создание бурного интереса и стимулирование конверсий.",
    src: "/development/promo.svg",
    width: 254,
    height: 251,
    price: 1000,
  },
  {
    title: "Сайт-каталог",
    description:
      "Сайт-каталог – это веб-ресурс, который предоставляет пользователю структурированный перечень товаров или услуг, обычно с подробными описаниями и характеристиками. Основная цель сайта-каталога – предоставить посетителям информацию о продукции и упростить им процесс выбора.",
    src: "/development/catalog.svg",
    width: 228,
    height: 254,
    price: 1600,
  },
];

export const task = [
  { title: "Привлечение новых клиентов", price: 100 },
  { title: "Удержание и повторные продажи", price: 10 },
  { title: "Информация о компании", price: 10 },
  { title: "Повышение лояльности и имиджа бренда", price: 10 },
  { title: "Оптимизация процессов", price: 10 },
];

export const design = [
  { title: "Индивидуальный дизайн", price: 100 },
  { title: "Уже есть", price: -100 },
];

export const logotip = [
  { title: "Нужен логип", price: 100 },
  { title: "Уже есть", price: 0 },
];

export const promotion = [
  { title: "Контекстная реклама", price: 200 },
  { title: "Поисковое продвижение SEO", price: 400 },
  { title: "Cоц. сети (smm) ", price: 300 },
  { title: "Не надо", price: 0 },
];

export const deadlines = [
  { title: "Срочно" },
  { title: 7 },
  { title: 14 },
  { title: 21 },
  { title: 30 },
  { title: 60 },
  { title: "Нужно обсудить" },
];
