import React, { useState } from "react";
import { createContext } from "react";
import { lockScroll } from "@/utils/helper";

export const ModalContext = createContext(null);

const Context = ({ children }) => {
  const [activeModalCallback, setActiveModalCallback] = useState(false);

  const changeActiveModalCallback = (active) => {
    lockScroll(active);
    setActiveModalCallback(active);
  };

  return (
    <ModalContext.Provider
      value={{
        activeModalCallback: activeModalCallback,
        setActiveModalCallback: changeActiveModalCallback,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default Context;
