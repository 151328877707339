"use client";
import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";

export function YandexMetrika() {
  const pathName = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    if (typeof ym === "function") {
      ym(96239385, "hit", window.location.href);
    }
  }, [pathName, searchParams]);

  return (
    <Script id="yandex-metrika" strategy="lazyOnload">
      {`
      
      ( function () {
        'use strict';

        // Флаг, что Метрика уже загрузилась.
        var loadedMetrica = false,
            // Переменная для хранения таймера.
            timerId;

        // Для бота Яндекса грузим Метрику сразу без "отложки",
        // чтобы в панели Метрики были зелёные кружочки
        // при проверке корректности установки счётчика.
        if ( navigator.userAgent.indexOf( 'YandexMetrika' ) > -1 ) {
            loadMetrica();
        } else {
            // Подключаем Метрику, если юзер начал скроллить.
            window.addEventListener( 'scroll', loadMetrica, {passive: true} );

            // Подключаем Метрику, если юзер коснулся экрана.
            window.addEventListener( 'touchstart', loadMetrica );

            // Подключаем Метрику, если юзер дернул мышкой.
            document.addEventListener( 'mouseenter', loadMetrica );

            // Подключаем Метрику, если юзер кликнул мышкой.
            document.addEventListener( 'click', loadMetrica );

            // Подключаем Метрику при полной загрузке DOM дерева,
            // с "отложкой" в 1 секунду через setTimeout,
            // если пользователь ничего вообще не делал (фоллбэк).
            document.addEventListener( 'DOMContentLoaded', loadFallback );
        }

        function loadFallback() {
            timerId = setTimeout( loadMetrica, 1000 );
        }

        function loadMetrica( e ) {


            // Если флаг загрузки Метрики отмечен,
            // то ничего более не делаем.
            if ( loadedMetrica ) {
                return;
            }

            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();
              for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
              k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
          
              ym(96239385, "init", {
                  defer: true,   
                  clickmap:true,
                  trackLinks:true,
                  accurateTrackBounce:true,
                  webvisor:true
              }); 

            // Отмечаем флаг, что Метрика загрузилась,
            // чтобы не загружать её повторно при других
            // событиях пользователя и старте фоллбэка.
            loadedMetrica = true;

            // Очищаем таймер, чтобы избежать лишних утечек памяти.
            clearTimeout( timerId );

            // Отключаем всех наших слушателей от всех событий,
            // чтобы избежать утечек памяти.
            window.removeEventListener( 'scroll', loadMetrica );
            window.removeEventListener( 'touchstart', loadMetrica );
            document.removeEventListener( 'mouseenter', loadMetrica );
            document.removeEventListener( 'click', loadMetrica );
            document.removeEventListener( 'DOMContentLoaded', loadFallback );
        }
    } )()
      `}
    </Script>
  );
}
