import React from "react";
import styles from "./googleMap.module.scss";
import { useResize } from "@/hooks/use-resize";
import dynamic from "next/dynamic";

const ModalCallback = dynamic(() => import("../ModalCallback"), {
  ssr: false,
});

const GoogleMap = () => {
  const { isScreenLg } = useResize();

  return (
    <div className={styles.cart}>
      <iframe
        title="google map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5742.968438235356!2d27.660370095861374!3d53.987132146233414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dbc8f119d5ce85%3A0xf59275b2abdf3c47!2z0YPQuy4gNDAg0LvQtdGCINCf0L7QsdC10LTRiyAzNCwg0JHQvtGA0L7QstC70Y_QvdGLLCDQnNC40L3RgdC60LDRjyDQvtCx0LvQsNGB0YLRjCAyMjMwNTM!5e0!3m2!1sru!2sby!4v1702327735671!5m2!1sru!2sby"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
      {isScreenLg && <ModalCallback />}
    </div>
  );
};

export default GoogleMap;
